<template>
	<b-sidebar
		class="right-sidebar landing-page"
		id="sidebar-landing"
		right
		shadow
		lazy
		@hidden="resetSidebar"
		@shown="loadData"
	>
		<div class="circle-transparent"></div>
		<div
			class="landing-cover"
			v-bind:style="{ backgroundImage: 'url(' + file_cover_url + ')' }"
		>
			<b-form-file
				class="button-file cover-button"
				v-model="file_cover"
				@input="updateCoverURL"
				accept="image/jpeg, image/png, image/gif"
			>
				<template #placeholder
					><div class="text-center">
						<b-icon-camera-fill />
						Modifica immagine di copertina
					</div>
				</template>
				<template #file-name>
					<div class="text-center">
						<b-icon-camera-fill />
						Modifica immagine di copertina
					</div>
				</template>
			</b-form-file>
			<b-icon
				v-if="loadingFile"
				class="loading-file"
				icon="arrow-clockwise"
				animation="spin"
				font-scale="2"
			></b-icon>
			<b-icon
				v-if="loadingFile"
				class="loading-file"
				icon="arrow-clockwise"
				animation="spin"
				font-scale="2"
			></b-icon>
			<div class="text-center cover-text d-flex align-items-end">
				<b-checkbox v-model="useUserProfilePic"></b-checkbox>Usa l'immagine di
				profilo dell'utente
			</div>

			<div
				class="cover-photo"
				v-bind:style="{ backgroundImage: 'url(' + file_photo_url + ')' }"
			>
				<b-form-file
					v-if="!useUserProfilePic"
					class="button-file photo-button"
					v-model="file_photo"
					@input="updatePhotoURL"
					accept="image/jpeg, image/png, image/gif"
				>
					<template #placeholder>
						<b-icon-camera-fill />
					</template>
					<template #file-name>
						<b-icon-camera-fill />
					</template>
				</b-form-file>
				<b-icon
					v-if="loadingFile"
					class="loading-file"
					icon="arrow-clockwise"
					animation="spin"
					font-scale="2"
				></b-icon>
			</div>
		</div>
		<div class="landing-body">
			<b-form-group label-for="title">
				<template #label>
					Titolo
					<InfoPopover
						class="ml-1"
						text="Inserisci qui il titolo della landing page"
					/>
				</template>
				<b-form-input v-model="title[title_lang]" />
				<LangOptions v-model="title_lang" :options="languages" />
			</b-form-group>
			<b-form-group label="Editor">
				<VueEditor class="editor" v-model="body[body_lang]" />
				<LangOptions v-model="body_lang" :options="languages" />
			</b-form-group>

			<div v-if="file_allegato_url" class="mb-5">
				Documento caricato:
				<div class="my-1">
					<a :href="file_allegato_url" target="_blank">
						{{ getTitleFromUrl(file_allegato_url) }}</a
					>
					<b-button @click="file_allegato_url = null" size="sm" class="ml-4"
						>Rimuovi</b-button
					>
				</div>
			</div>

			<b-form-group v-else label="Caricamento documenti">
				<b-form-file
					v-model="file_allegato"
					accept="application/pdf"
					@input="updateAllegatoURL"
					placeholder="Seleziona i documenti da caricare"
					drop-placeholder="Trascina i documenti qui"
					class="mb-3"
				>
				</b-form-file>
				<b-icon
					v-if="loadingFile"
					class="loading-file"
					icon="arrow-clockwise"
					animation="spin"
					font-scale="2"
				></b-icon>

				<!-- <FilesUploadManager v-model="documentiCaricati"></FilesUploadManager> -->
			</b-form-group>

			<div class="accordion-button">
				<div
					class="accordion-header d-flex justify-content-between align-items-center"
					v-b-toggle.form-contatto
				>
					<span>Form di contatto</span>
					<b-icon-chevron-right />
				</div>
				<b-collapse id="form-contatto">
					<div class="accordion-body">
						<b-row>
							<b-col lg="10">
								<div
									class="d-flex align-items-center"
									:key="item.id || item.fake_id"
									v-for="item in custom_fields"
								>
									<b-icon icon="plus-circle" class="mr-2 mt-2" />
									<b-form-group label-for="mail" class="w-100">
										<template #label>
											{{ item.name[custom_field_lang] }}
											<span v-if="item.required"> * </span>
											<b-icon-trash
												@click="deleteCustomField(item)"
												class="ml-1 pointer"
											/>
										</template>
										<b-form-input />
										<b-input-group-append> </b-input-group-append>
									</b-form-group>
								</div>
								<div class="mt-3 mb-3 font-weight-bold">
									Aggiungi campo personalizzato
								</div>
								<b-form-group label-for="custom_field_name" label="Nome campo">
									<b-form-input
										v-model="custom_field_name[custom_field_lang]"
									/>
									<LangOptions
										v-model="custom_field_lang"
										:options="languages"
									/>
									<div
										class="d-flex align-items-center justify-content-between mt-4 pt-2"
									>
										<b-form-checkbox v-model="custom_field_required" switch>
											Campo obbligatorio
										</b-form-checkbox>
										<b-button squared size="md" @click="addCustomField"
											>Aggiungi</b-button
										>
									</div>
								</b-form-group>
								<b-form-group>
									<template #label>
										<span class="font-weight-bold">
											Azione al bottone di invio
										</span>
									</template>
									<b-form-radio-group
										v-model="form_destination"
										:options="form_destination_options"
										plain
										stacked
									></b-form-radio-group>
								</b-form-group>
								<b-row class="align-items-center">
									<b-col>
										<b-form-checkbox v-model="codiciSconto" switch>
											Codici sconto
										</b-form-checkbox>
									</b-col>
									<b-col v-if="codiciSconto">
										<b-form-input v-model="numeroCodiciSconto" />
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</div>
				</b-collapse>
			</div>

			<div class="mt-5 mb-5">
				<b-button
					v-if="isLoading"
					squared
					variant="primary"
					size="lg"
					class="big-button"
					disabled
					><b-spinner small type="grow"></b-spinner> Loading...</b-button
				>
				<b-button
					v-else
					squared
					variant="primary"
					size="lg"
					class="big-button"
					@click="saveItem"
					>Save</b-button
				>
				<b-button
					squared
					variant="outline-primary"
					size="lg"
					v-b-toggle.sidebar-landing
					class="big-button ml-2"
					>Annulla</b-button
				>
			</div>
		</div>
		<div class="mb-5 mt-5"></div>
	</b-sidebar>
</template>

<script>
	import { VueEditor } from "vue2-editor";
	import AxiosService from "./../axiosServices/AxiosService";
	// import FilesUploadManager from "./FilesUploadManager.vue";
	export default {
		components: {
			VueEditor,
			// FilesUploadManager,
		},
		props: ["landing", "default_lang", "languages", "idCampaign"],
		data() {
			return {
				id: 0,
				title: {},
				body: {},
				file_photo_url: "",
				file_cover_url: "",
				loadingFile: false,
				file_allegato_url: "",
				custom_fields: [],
				form_destination: "form",
				idForm: 0,
				idSurvey: 0,
				progresso_obiettivo: 0,
				codiciSconto: false,
				numeroCodiciSconto: 0,
				file_photo: null,
				file_cover: null,
				file_allegato: null,
				custom_field_name: {},
				custom_field_required: false,
				form_destination_options: [
					{ value: "form", text: "Invia form" },
					{ value: "survey", text: "Attiva survey", disabled: true },
					{
						value: "talento",
						text: "Attiva analisi del talento",
						disabled: true,
					},
				],
				isLoading: false,

				title_lang: null,
				body_lang: null,
				custom_field_lang: null,

				useUserProfilePic: false,
				documentiCaricati: [],
			};
		},
		service: null,
		created() {
			this.service = new AxiosService("Landing");
			this.resetData();
		},

		methods: {
			getTitleFromUrl(url) {
				let fileName = url.split("_").pop();

				return decodeURIComponent(fileName);
			},
			resetSidebar() {
				this.resetData();
				this.$emit("reset-landing");
			},
			resetData() {
				this.id = 0;
				this.title = {};
				this.body = {};
				this.file_photo = null;
				this.file_cover = null;
				this.file_allegato = null;
				this.file_photo_url = "";
				this.file_cover_url = "";
				this.file_allegato_url = "";
				this.custom_fields = [];
				this.idForm = 0;
				this.idSurvey = 0;
				this.progresso_obiettivo = 0;

				this.custom_field_name = {};
				this.custom_field_required = false;

				this.title_lang = this.default_lang;
				this.body_lang = this.default_lang;
				this.custom_field_lang = this.default_lang;
			},
			loadData() {
				if (this.landing) {
					this.id = this.landing.id;
					this.title = this.landing.title;
					this.body = this.landing.body;
					this.file_photo_url = this.landing.url_profile;
					this.file_cover_url = this.landing.url_cover;
					this.file_allegato_url = this.landing.fileAllegato;
					this.custom_fields = this.landing.form.fields;
					this.idForm = this.landing.form.idForm;
					this.idSurvey = this.landing.form.idSurvey;
					this.progresso_obiettivo = this.landing.progresso_obiettivo;
				}
			},
			updatePhotoURL() {
				this.file_photo_url = URL.createObjectURL(this.file_photo) || "";
			},
			updateCoverURL() {
				this.file_cover_url = URL.createObjectURL(this.file_cover) || "";
			},
			updateAllegatoURL() {
				this.file_allegato_url = URL.createObjectURL(this.file_allegato) || "";
			},
			addCustomField() {
				this.custom_fields = [
					...this.custom_fields,
					{
						fake_id: Date.now(),
						name: this.custom_field_name,
						required: this.custom_field_required,
					},
				];
				this.custom_field_name = {};
				this.custom_field_required = false;
				this.custom_field_lang = this.default_lang;
			},
			deleteCustomField(item) {
				this.custom_fields = this.custom_fields.filter(function(current) {
					return current != item;
				});
			},
			async saveItem() {
				this.isLoading = true;
				this.loadingFile = true;
				let item = {
					id: this.id,
					url_cover: this.file_cover_url,
					url_profile: this.file_photo_url,
					fileAllegato: this.file_allegato_url,
					title: this.title,
					body: this.body,
					social: [],
					form: {
						idForm: this.idForm,
						action: this.form_destination,
						idSurvey: this.idSurvey,
						fields: this.custom_fields,
					},
					progresso_obiettivo: this.progresso_obiettivo,
				};

				const params = [{ param: "idCampagna", value: this.idCampaign }];

				const validationObject = this.$validate("landing", item);
				if (!validationObject.validation) {
					this.$errorToast(validationObject.messages.join(", "));
					this.isLoading = false;
					return false;
				}

				if (this.file_photo) {
					if (this.file_photo.size > 3 * 1024 * 1024) {
						alert("I file non possono superare il massimo di 3MB");
						return false;
					}
					const formData = new FormData();
					formData.append("pic", this.file_photo);
					let imageName = await this.service
						.uploadFile(formData)
						.then((res) => res.data)
						.finally(() => {
							this.loadingFile = false;
						});
					let imageUrl = this.$driveAddress + imageName;
					item.url_profile = imageUrl.replaceAll(" ", "%20");
				}

				if (this.file_cover) {
					if (this.file_cover.size > 3 * 1024 * 1024) {
						alert("I file non possono superare il massimo di 3MB");
						return false;
					}
					const formData = new FormData();
					formData.append("pic", this.file_cover);
					let imageName = await this.service
						.uploadFile(formData)
						.then((res) => res.data)
						.finally(() => {
							this.loadingFile = false;
						});
					let imageUrl = this.$driveAddress + imageName;
					item.url_cover = imageUrl.replaceAll(" ", "%20");
				}

				if (this.file_allegato) {
					if (this.file_allegato.size > 3 * 1024 * 1024) {
						alert("I file non possono superare il massimo di 3MB");
						return false;
					}
					const formData = new FormData();
					formData.append("doc", this.file_allegato);
					let allegatoName = await this.service
						.uploadFile(formData)
						.then((res) => res.data)
						.finally(() => {
							this.loadingFile = false;
						});
					let fileUrl = this.$driveAddress + allegatoName;
					item.fileAllegato = fileUrl.replaceAll(" ", "%20");
				}

				if (item.id) {
					this.service
						.update(item)
						.then((response) => {
							if (response.id == item.id) {
								this.$emit("edit-landing", item);
								this.$root.$emit("bv::toggle::collapse", "sidebar-landing");
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						})
						.finally(() => {
							this.isLoading = false;
						});
				} else {
					this.service
						.create(item, params)
						.then((response) => {
							if (response.id) {
								item = response;
								this.$emit("add-landing", item);
								this.$root.$emit("bv::toggle::collapse", "sidebar-landing");
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},
		},
	};
</script>
